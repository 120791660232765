const status = "Live";

export const serverLink = status === "Dev"
  ? "http://localhost:4480/"
  // : "https://smartsourcingapi.com:5001/" //LUX MUNDI
  // : "https://smartsourcingapi.com:5002/" // BABA AHMED
  // : "https://smartsourcingapi.com:5003/" // AL-ANSAR
  // : "https://smartsourcingapi.com:5004/" // OLIVIA
  : "https://gargatechapi.com.ng:5008/" //COSMOPOLITAN



// export const projectName = "Olivia University";
// export const projectCode = "OLIVIA_UNIVERSITY_WEBSITE_ADMIN";
// export const serverStatus = status;
// export const shortCode = "OUB";
// export const projectURL = "https://oliviauniversity.com/";
// export const projectPhone = "+25767800004";
// export const projectEmail = "info@oliviauniversity.com";
// export const projectAddress = "Olivia University Bujumbura, № 4&5, Mayugi, Avenue, Mukaza, Bujumbura-Burundi";
// export const projectFooter = "Copyright Olivia University, Burundi";
// export const projectLogo = `${projectURL}logo.png`;
// export const projectUploadAPI = "305e0ddf925a3fc5dd34d314f8230349";






// export const serverStatus = status;
// export const projectAddress =
//     "No 306, Sharada Phase I Kano State, Nigeria.";
// export const projectFooter = "Copyright Baba-Ahmed University, Kano";

// export const projectName = "Baba-Ahmed University";
// export const projectCode = "BABA_AHMED_UNIVERSITY_STAFF_PORTAL";
// export const shortCode = "BAUK";
// export const projectURL = "https://babaahmeduniversity.edu.ng/";
// export const projectLogo = "https://babaahmeduniversity.edu.ng/logo.png";
// export const projectPhone = "+2348035125748, +2348087555544";
// export const projectEmail = "info@babaahmeduniversity.edu.ng";
// export const projectHREmail = "hr@babaahmeduniversity.edu.ng";
// export const projectViceChancellor = "Adamu Idris Tanko";
// export const projectUploadAPI = "305e0ddf925a3fc5dd34d314f8230349";




// export const serverStatus = status;
// export const projectAddress =
//   "No 24 Ahmadu Gonimi Road, Off Gombole, Old GRA Maiduguri, Borno State.";
// export const projectFooter = "Copyright Al-Ansar University, Maiduguri";

// export const projectName = "Al-Ansar University";
// export const projectCode = "AL_ANSAR_UNIVERSITY_ADMIN_PORTAL";
// export const shortCode = "AUM";
// export const projectURL = "https://aum.edu.ng/";
// export const projectLogo = "https://aum.edu.ng/logo.png";
// export const projectPhone = "+234 803 629 5382";
// export const projectEmail = "info@aum.edu.ng";
// export const projectHREmail = "hr@aum.edu.ng";
// export const projectViceChancellor = "M.K Dikwa";
// export const projectUploadAPI = "305e0ddf925a3fc5dd34d314f8230349";



//
// export const serverStatus = status;
// export const projectAddress =  "New Ndagbo City, Isiama Afaraukwu-Ibeku, Umuahia, Abia State, Nigeria.";
// export const projectFooter = "Copyright Lux Mundi University, Umuahia";
// export const projectName = "Lux Mundi University";
// export const projectCode = "LUX_MUNDI_UNIVERSITY";
// export const shortCode = "LMU";
// export const projectURL = "https://luxmundi.edu.ng/";
// export const projectLogo = "https://luxmundi.edu.ng/logo.png";
// export const projectPhone = "+2348035125748, +2348087555544";
// export const projectEmail = "info@luxmundi.edu.ng";
// export const projectHREmail = "hr@luxmundi.edu.ng";
// export const projectViceChancellor = "Professor Paul Eidson";
// export const projectUploadAPI = "305e0ddf925a3fc5dd34d314f8230349";




export const serverStatus = status;
export const projectAddress = "Abuja Nigeria";
export const projectFooter = "Copyright Cosmopolitan, Abuja";
export const projectName = "Cosmopolitan University";
export const projectCode = "COSMOPOLITAN_UNIVERSITY";
export const shortCode = "CU";
export const projectURL = "https://cosmopolitan.edu.ng/";
export const projectLogo = "https://cosmopolitan.edu.ng/logo.png";
export const projectPhone = "+2348077977795";
export const projectEmail = "info@cosmopolitan.edu.ng";
export const projectHREmail = "hr@cosmopolitan.edu.ng";
export const projectViceChancellor = "Prof Carl Adams";
export const projectUploadAPI = "305e0ddf925a3fc5dd34d314f8230349";


// SOCIAL MEDIA
// OLIVIA
// export const projectFacebook = "https://www.facebook.com/";
// export const projectTwitter = "https://www.twitter.com/";
// export const projectInstagram = "https://instagram.com/oliviauniversitybujumbura?igshid=YmMyMTA2M2Y=";
// export const projectLinkedIn = "https://linkedin.com/?lang=en";
// export const projectYoutube = "https://www.youtube.com/";

// BABA AHMAD
// export const projectFacebook = "https://www.facebook.com/";
// export const projectTwitter = "https://www.twitter.com/";
// export const projectInstagram = "https://instagram.com/?lang=en";
// export const projectLinkedIn = "https://linkedin.com/?lang=en";
// export const projectYoutube = "https://www.youtube.com/";

// ALANSAR
export const projectFacebook = "https://www.facebook.com/";
export const projectTwitter = "https://www.twitter.com/";
export const projectInstagram = "https://instagram.com/?lang=en";
export const projectLinkedIn = "https://linkedin.com/?lang=en";
export const projectYoutube = "https://www.youtube.com/";

// LUXMUNDI
// export const projectFacebook = "https://www.facebook.com/";
// export const projectTwitter = "https://www.twitter.com/";
// export const projectInstagram = "https://instagram.com/?lang=en";
// export const projectLinkedIn = "https://linkedin.com/?lang=en";
// export const projectYoutube = "https://www.youtube.com/";


// COSMOPOLITAN
// export const projectFacebook = "https://www.facebook.com/";
// export const projectTwitter = "https://www.twitter.com/";
// export const projectInstagram = "https://instagram.com/?lang=en";
// export const projectLinkedIn = "https://linkedin.com/?lang=en";
// export const projectYoutube = "https://www.youtube.com/";